export const environment = {
    production: false,
    stripe_token: 'STRIPE_TOKEN',
    paypal_token: 'PAYPAL_TOKEN',
    loadGif: 'assets/images/loader.gif',
    currency: '',

    // PARAMETROS MIMAS APP
    firebase: {
        apiKey: "AIzaSyBP29SgRFwlpU9Fwwdc1OI4hbwpE-aAbXg",
        authDomain: "mimas-fire.firebaseapp.com",
        projectId: "mimas-fire",
        storageBucket: "mimas-fire.appspot.com",
        messagingSenderId: "624213884632",
        appId: "1:624213884632:web:b3fbd9486cea95e5c621ad"
    },
    googleMapsKey: 'AIzaSyC0N_UyDvlLly4CbXV3zOrGwgvmZIIWl_4',
    urlBase: 'https://us-central1-mimas-fire.cloudfunctions.net',
    apikey: '437fa6bf05e-4d7cce784420c-4cda-1bbcc655',
    urlBaseWompy: 'https://us-central1-mimas-fire.cloudfunctions.net/Wompi',

    // TWILIO CREDENCIALES IanOS DEV
    twilio: {
        TWILIO_SERVICE_ENDPOINT: 'https://verify.twilio.com/v2/Services/VA6f37e2f3e25ae98433a02926ec75c77a/Verifications',
        TWILIO_SERVICE_VERIFICATION_CHECK: `https://verify.twilio.com/v2/Services/VA6f37e2f3e25ae98433a02926ec75c77a/VerificationCheck`,
        TWILIO_AUTH_TOKEN: '13b6af0768f7255a066f3436df02f6ca',
        TWILIO_ACCOUNT_SID: 'ACb5f6530b25d1d3d182d2df34d1747117',
        TWILIO_SERVICE_TOKEN: 'VA6f37e2f3e25ae98433a02926ec75c77a',
        TWILIO_MESSAGING_SERVICE_SID: 'MG7302002067524cd572d66063ff774b61'
    },

    // CREDENCIALES DE PRO ITWC
    wompi: {
        grant_type: 'client_credentials', // Dejar siempre client_credentials.
        audience: 'wompi_api', // Dejar siempre "wompi_api"
        client_id: 'dc4c16b2-08d9-4478-9037-40a580004f6b', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        client_secret: '2c1c79a5-8315-4989-8ade-1469183c0810', //Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
    },

    mailchimp: {
        apiKey: '4d1b5faa7e6c99414d58feb265309a36-us22',
        server: 'us22',
        listUniqueId: 'd5e6af4f94'
    }
};

